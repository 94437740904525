import React from "react"
import withNoIndex from "components/_common/NoIndex/withNoIndex"
import Button from "components/_common/Button"

const NewButtonsPage = () =>
    <main style={{ paddingTop: "20px", paddingBottom: "20px" }}>
        <div>
            <div style={{ marginTop: "50px" }}>
                <Button withIcon={true} text="Large Button" size="large" />
            </div>
            <div style={{ marginTop: "50px" }}>
                <Button text="Large Button" size="large" />
            </div>
            <div style={{ marginTop: "50px" }}>
                <Button size="small" withIcon={true} text="Small Button" />
            </div>
            <div style={{ marginTop: "50px" }}>
                <Button size="small" text="Small Button" />
            </div>
            <div>
                <div style={{ marginTop: "50px" }}>
                    <Button withIcon={true} text="Large Button" size="large" variant="link" />
                </div>
                <div style={{ marginTop: "50px" }}>
                    <Button text="Large Button" size="large" variant="link" />
                </div>
                <div style={{ marginTop: "50px" }}>
                    <Button size="small" withIcon={true} text="Small Button" variant="link" />
                </div>
                <div style={{ marginTop: "50px" }}>
                    <Button size="small" text="Small Button" variant="link" />
                </div>
            </div>
            <div>
                <div style={{ marginTop: "50px" }}>
                    <Button withIcon={true} text="Large Button" size="large" variant="ghost" />
                </div>
                <div style={{ marginTop: "50px" }}>
                    <Button text="Large Button" size="large" variant="ghost" />
                </div>
                <div style={{ marginTop: "50px" }}>
                    <Button size="small" withIcon={true} text="Small Button" variant="ghost" />
                </div>
                <div style={{ marginTop: "50px" }}>
                    <Button size="small" text="Small Button" variant="ghost" />
                </div>
            </div>
            <div>
                <div style={{ marginTop: "50px" }}>
                    <Button variant="icon" color="dark" />
                </div>
                <div style={{ marginTop: "50px" }}>
                    <Button variant="icon" color="dark" size="small" />
                </div>
            </div>
        </div>
        <div style={{ backgroundColor: "#0d2440" }}>
            <div style={{ marginTop: "50px" }}>
                <Button withIcon={true} text="Large Button" size="large" color="light" />
            </div>
            <div style={{ marginTop: "50px" }}>
                <Button text="Large Button" size="large" color="light" />
            </div>
            <div style={{ marginTop: "50px" }}>
                <Button size="small" withIcon={true} text="Small Button" color="light" />
            </div>
            <div style={{ marginTop: "50px" }}>
                <Button size="small" text="Small Button" color="light" />
            </div>
            <div>
                <div style={{ marginTop: "50px" }}>
                    <Button withIcon={true} text="Large Button" size="large" variant="link" color="light" />
                </div>
                <div style={{ marginTop: "50px" }}>
                    <Button text="Large Button" size="large" variant="link" color="light" />
                </div>
                <div style={{ marginTop: "50px" }}>
                    <Button size="small" withIcon={true} text="Small Button" variant="link" color="light" />
                </div>
                <div style={{ marginTop: "50px" }}>
                    <Button size="small" text="Small Button" variant="link" color="light" />
                </div>
            </div>
            <div>
                <div style={{ marginTop: "50px" }}>
                    <Button withIcon={true} text="Large Button" size="large" variant="ghost" color="light" />
                </div>
                <div style={{ marginTop: "50px" }}>
                    <Button text="Large Button" size="large" variant="ghost" color="light" />
                </div>
                <div style={{ marginTop: "50px" }}>
                    <Button size="small" withIcon={true} text="Small Button" variant="ghost" color="light" />
                </div>
                <div style={{ marginTop: "50px" }}>
                    <Button size="small" text="Small Button" variant="ghost" color="light" />
                </div>
            </div>
            <div>
                <div style={{ marginTop: "50px" }}>
                    <Button variant="icon" color="light" />
                </div>
                <div style={{ marginTop: "50px" }}>
                    <Button variant="icon" color="light" size="small" />
                </div>
            </div>
        </div>
    </main>



export default withNoIndex(NewButtonsPage)
